import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64694b48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-container settings-content" }
const _hoisted_2 = {
  key: 0,
  class: "emergency-pin"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_vue3_markdown_it = _resolveComponent("vue3-markdown-it")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, {
    returnRoute: _ctx.returnRoute,
    shouldShowShareIcon: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ion_icon, {
          src: _ctx.contentImage,
          class: "icon"
        }, null, 8, ["src"]),
        _createVNode(_component_vue3_markdown_it, {
          source: _ctx.contentText,
          breaks: ""
        }, null, 8, ["source"]),
        _createVNode(_component_ion_item, {
          class: "app-background ion-no-padding",
          lines: "none"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_component_ion_toggle, {
              onClick: _ctx.updatePreference,
              slot: "start",
              name: "calcToggle",
              color: "app-primary",
              checked: _ctx.shouldUseSafetyCalculator,
              key: _ctx.shouldUseSafetyCalculator
            }, null, 8, ["onClick", "checked"])),
            _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.safetyCalculatorToggleText), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_button, {
          onClick: _ctx.changePin,
          color: "app-primary",
          expand: "block"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("messages.securityPage.changePinButtonText")), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        (_ctx.showSecurityDialog)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.t("messages.securityPage.emergencyPIN")), 1))
          : _createCommentVNode("", true),
        (_ctx.showSecurityDialog)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.emergencyPinText), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["returnRoute"]))
}