
import {ref, computed, defineComponent} from "vue";
import { IonButton, IonItem, IonToggle, IonLabel } from "@ionic/vue";
import { useStore } from "vuex";
import translate from "@/views/translate";
import { useRouter } from "vue-router";
import { savePreferences } from "@/storage/DataStorage";
import {getImageDataUri} from "@/utilities/Compatibility";

export default defineComponent({
    name: "Security",
    components: { IonButton, IonItem, IonToggle, IonLabel },
    async ionViewWillEnter() {
        this.returnRoute = this.$store.state.prevPage;
        this.$store.dispatch("setHeaderImage", getImageDataUri(this.t("messages.securityPage.headerImage")));
        this.$store.dispatch("setHeaderTitle", "");
        const { shouldUseSafetyCalculator } = this.store.getters.getPreferences();
        this.shouldUseSafetyCalculator = shouldUseSafetyCalculator;
    },
    setup() {
        const { t } = translate();
        const store = useStore();
        const returnRoute = ref("" as string);
        const shouldUseSafetyCalculator = ref(true);
        const router = useRouter();
        const showSecurityDialog = computed(() => t("messages.buildConfigs.config.securityDialog") === "true");

        const changePin = async () => {
            try {
                const preferences = store.getters.getPreferences();
                preferences.shouldSetPin = true;
                await savePreferences(preferences);
                await router.push({ name: "calculator" });
            }
            catch {
                router.push({ name: "calculator" });
            }          
        };

        const updatePreference = async () => {
            const preferences = store.getters.getPreferences();
            preferences.shouldUseSafetyCalculator = !preferences.shouldUseSafetyCalculator;
            await savePreferences(preferences);
            await store.dispatch("setShouldShowCalculatorIcon", preferences.shouldUseSafetyCalculator);
            
            if (preferences.shouldSetPin && preferences.shouldUseSafetyCalculator) {
                router.push({ name: "calculator" });
            }
        };

        return {
            t,
            store,
            returnRoute,
            updatePreference,
            contentImage: computed(() =>getImageDataUri(t("messages.securityPage.contentImage"))),
            contentText: computed(() => t("messages.buildConfigs.config.alternatePinScreen") ? t("messages.securityPage.contentAlternateText") : t("messages.securityPage.contentText")),
            safetyCalculatorToggleText: computed(() => t("messages.buildConfigs.config.alternatePinScreen") ? t("messages.securityPage.safetyCalculatorToggleAlternateText") : t("messages.securityPage.safetyCalculatorToggleText")),
            emergencyPinText: computed(() => t("messages.buildConfigs.config.alternatePinScreen") ? t("messages.securityPage.emergencyPINAlternateText") : t("messages.securityPage.emergencyPINText")),
            shouldUseSafetyCalculator,
            changePin,
            showSecurityDialog
        };
    },
});
